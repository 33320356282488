<template>
  <div :class="classes.wrapper">
    <div :class="classes.author">
      <div :class="classes.authorContent">
        <div :class="classes.userInitials">
          {{ getInitials(review.CustomerName) }}
        </div>
        <div :class="classes.row">
          <div :class="classes.row">
            <div :class="classes.authorName">
              {{ review.CustomerName }}
            </div>
            <div v-if="review.Source" :class="classes.source">
              <div :class="classes.sourceCol">
                <span> Отзыв с </span>
                <img
                  :class="classes.sourceImage"
                  :src="reviewSource.imageSource"
                  :alt="'Источник отзыва – ' + review.Source"
                  :width="reviewSource.width"
                  :height="reviewSource.height"
                >
              </div>
              <div :class="classes.sourceCol">
                5,0
                <Icon
                  name="common:star"
                  size="10"
                  :class="cx(classes.sourceColI)"
                />
              </div>
            </div>
          </div>

          <div v-if="review.Device" :class="classes.device">
            Сделка состоялась «{{ review.Device }}»
          </div>
        </div>
      </div>
    </div>
    <div>
      <BaseSimpleImageGallery
        v-if="review.Images && review.Images?.length"
        :image-sources="review.Images"
        :class="classes.media"
      />
      <div :class="classes.text">
        {{ review.Text }}
      </div>
      <div :class="classes.actions">
        <time :datetime="review.CreatedAt" :class="classes.date">
          {{ formatDate(review.CreatedAt) }}
        </time>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, shallowRef, computed } from 'vue'
import { cx } from 'cva'
import type { Review } from '~/types/models'

const props = defineProps({
  review: {
    type: Object as PropType<Review>,
    default() {
      return {}
    },
    required: true,
  },
})

const classes = {
  wrapper: cx(['mb-[24px]', 'text-[14px]/[21px]', 'xl:text-[16px]/[24px]']),
  author: cx(['flex', 'mb-0.5']),
  authorName: cx(['font-semibold', 'text-primary', 'leading-none']),
  row: cx(['grid', 'grid-rows-[repeat(2,auto)]', 'gap-y-1', 'xl:gap-y-2']),
  text: cx(['mb-[17px]', 'xl:mb-[19px]']),
  device: cx(['text-[11px]/[12px]', 'text-ghost']),
  actions: cx([
    'flex',
    'items-center',
    'text-primary',
    'justify-end',
    'xl:justify-start',
  ]),
  date: cx(['text-[10px]', 'text-ghost', 'xl:text-[11px]']),
  like: cx([
    'flex',
    'justify-around',
    'items-center',
    'bg-bg-main-secondary',
    'rounded-lg',
    'py-1',
    'px-2.5',
    'transition',
    'duration-300',
    'text-[10px]',
    'text-ghost',
    'fill-[rgb(189,_189,_189)]',
    'xl:text-[11px]',
    'group',
  ]),
  likeIcon: 'group-hover:fill-[rgb(255,_131,_131)]',
  likeText: 'mr-[5px]',
  media: 'mb-4',
  authorContent: cx([
    'mb-2.5',
    'w-fit',
    'grid',
    'grid-cols-[auto_1fr]',
    'gap-x-4',
  ]),
  userInitials: cx([
    'w-[49px]',
    'h-[49px]',
    'bg-bg-main-thirdly',
    'rounded-[50%]',
    'flex',
    'justify-center',
    'items-center',
    'font-bold',
    'text-accent',
    'xl:w-[60px]',
    'xl:h-[60px]',
  ]),
  source: cx([
    'text-[11px]',
    'grid',
    'grid-cols-[auto_1fr]',
    'gap-x-4',
    'items-center',
  ]),
  sourceCol: cx(['flex', 'items-center', 'text-primary']),
  sourceColI: cx(['text-yellow', 'ml-[5px]']),
  sourceImage: cx(['ml-[3px]', 'w-auto', 'h-[11px]']),
}

interface Source {
  name: string
  imageSource: string
  width: number
  height: number
}

const sources = shallowRef<Source[]>([
  {
    name: 'Яндекс',
    imageSource: '/static/images/review-section/brands/yandex.png',
    width: 104,
    height: 40,
  },
  {
    name: '2Gis',
    imageSource: '/static/images/review-section/brands/2gis.png',
    width: 101,
    height: 35,
  },
  {
    name: 'Avito',
    imageSource: '/static/images/review-section/brands/avito.png',
    width: 98,
    height: 35,
  },
])

const getInitials = (name: string) => {
  const words = name.split(' ')

  let initials = ''
  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    initials += word[0].toUpperCase()
  }

  return initials
}

const reviewSource = computed(
  () =>
    sources.value.find(
      source => source.name === props.review.Source,
    ) as Source,
)

const formatDate = (value: string) => {
  return new Date(value).toLocaleString('ru', { dateStyle: 'long' })
}
</script>
<template>
  <div :class="classes.wrapper">
    <img
      v-for="(imageSource, index) in imageSources"
      :key="index"
      :src="imageSource"
      :class="classes.image"
      height="192"
      alt="image"
    >
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'

defineProps({
  imageSources: {
    type: Array<string>,
    required: true,
  },
})

const classes = {
  wrapper: cx(['flex', 'flex-row', 'max-w-full', 'overflow-x-auto']),
  image: cx([
    'rounded-xl',
    'mb-1.5',
    'object-contain',
    'h-[295px]',
    'mr-[26px]',
    'xl:mb-0',
  ]),
}
</script>
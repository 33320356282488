<template>
  <div :class="['container', sectionStyles]">
    <div ref="reviewsContentElement" :class="classes.content">
      <div>
        <div :class="classes.galleryTitle">
          Отзывы<br class="hidden xl:block">
          о Pedant.Market
        </div>
        <div :class="classes.galleryAssessment">
          <div :class="classes.galleryAssessmentNumber">
            4,9
          </div>
          <div :class="classes.galleryAssessmentStars">
            <Icon
              v-for="number in 5"
              :key="number"
              name="common:star"
              size="18"
              class="text-yellow"
            />
          </div>
          <div :class="classes.galleryAssessmentStatistics">
            1 764 отзывов · 8 385 клиентов
          </div>
        </div>
        <div :class="classes.galleryList">
          <div
            v-for="(_item, index) in 4"
            :key="index"
            :class="classes.galleryItem"
            :style="{
              backgroundImage:
                'url('
                + `/static/images/review-section/reviews/${index + 1}.jpg`
                + ')',
            }"
            @click="openLightbox(index)"
          >
            <a
              :href="`/static/images/review-section/reviews/${index + 1}.jpg`"
              class="glightbox"
              aria-label="фото с устройством"
            ></a>
          </div>
        </div>
      </div>
      <div :class="classes.reviews">
        <BaseReviewItem
          v-for="review in reviews"
          :key="review.Id"
          :review="review"
        />
        <BasePagination
          :class="classes.pagination"
          :meta="meta"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { ref, watch, reactive, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import type { ReviewsListParams } from '~/types/models'
import { sectionStyles } from '@/utils'

const { $GLightbox, $SweetScroll } = useNuxtApp()

const classes = {
  content: cx([
    'grid',
    'xl:grid-cols-[354px_auto]',
    'gap-8',
    'xl:gap-[65px]',
    'p-7',
    'xl:pb-[48px]',
    'xl:pt-[48px]',
    'xl:pl-[56px]',
    'xl:pr-[56px]',
    'bg-bg-main-primary',
    'rounded-[12px]',
    'xl:rounded-[24px]',
  ]),
  galleryTitle: cx([
    'text-[21px]/[25px]',
    'font-bold',
    'text-primary',
    'mb-6',
    'xl:text-[36px]/[46px]',
  ]),
  galleryAssessment: cx([
    'grid',
    `[grid-template-areas:'number_stars''number_statistics']`,
    'grid-cols-[auto_1fr]',
    'gap-x-4',
    'mb-4',
    'xl:mb-9',
    'xl:gap-y-2',
  ]),
  galleryAssessmentNumber: cx([
    '[grid-area:number]',
    'text-[54px]/[59px]',
    'xl:text-[64px]/[70px]',
    'text-primary',
  ]),
  galleryAssessmentStars: cx([
    '[grid-area:stars]',
    'grid',
    'grid-cols-[repeat(5,_auto)]',
    'gap-x-1',
    'mr-auto',
    'self-end',
    'text-[18px]/[0px]',
    'text-yellow',
  ]),
  galleryAssessmentStatistics: cx([
    '[grid-area:statistics]',
    'text-[14px]',
    'text-secondary',
    'self-center',
    'xl:text-[16px]/[19px]',
  ]),
  galleryList: cx([
    'grid',
    'grid-cols-[repeat(4,_auto)]',
    'justify-between',
    'mb-4',
    'xl:grid-cols-[repeat(4,_1fr)]',
    'xl:gap-x-4',
    'xl:gap-y-6',
    'xl:mb-0',
  ]),
  galleryItem: cx([
    'relative',
    'w-[64px]',
    'h-[64px]',
    'rounded-xl',
    'bg-center',
    'bg-cover',
    'cursor-zoom-in',
    'xl:w-[72px]',
    'xl:h-[72px]',
  ]),
  galleryItemLink: cx(['block', 'w-full', 'h-full']),
  reviews: cx(['xl:flex', 'flex-col', 'justify-between']),
  pagination: cx(['ml-auto', 'mr-auto', 'xl:ml-[0px]']),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const reviewsStore = useReviewsStore()
const { list: reviews, meta } = storeToRefs(reviewsStore)

// загружаем отзывы
if (!reviewsStore.list?.length)
  await useAsyncData('fetchReviews', () =>
    reviewsStore.getList().then(() => true),
  )

const reviewsContentElement = ref<HTMLDivElement>()

const filter = reactive({
  page: 1,
  sort_by: 'LikesCount',
  sort_direction: 'desc',
})

watch(
  () => filter,
  (filter) => {
    reviewsStore.getList(filter as ReviewsListParams)
  },
  { deep: true },
)

const changePage = (newPage: number = 1) => {
  filter.page = newPage
  scrollToReviewsStart()
}

const scrollToReviewsStart = () => {
  if (!reviewsContentElement.value) return

  const headerElement = document.querySelector('header') as HTMLDivElement
  if (!headerElement) return

  const sweetScroll = new $SweetScroll({ duration: 1500 })
  const headerElementHeight = headerElement.offsetHeight

  sweetScroll.to(
    reviewsContentElement.value.offsetTop
    - headerElementHeight
    - 20
    - document.documentElement.clientHeight / 5,
  )
}
const openLightbox = (index: number) => {
  const isLastImage
    = (index === 14 && xlAndLarger) || (index === 3 && !xlAndLarger.value)

  const lightbox = $GLightbox({
    selector: 'a.glightbox',
    startAt: !isLastImage ? index : 0,
  })

  lightbox.open()
}

onMounted(() => {
  const currentPage = meta?.value?.current_page
  if (currentPage) filter.page = currentPage
})
</script>